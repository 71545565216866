import React from 'react'
import FormHandler from '../form-handler'
import { withModalManager } from '../modal-manager'
import { withRestricted } from '../restricted'
import EnterpriseApi from '@/lib/api/enterprise'

function RegisterParentAccount (props) {
  const {
    children,
    modal,
    handleSubmit,
    handleChange,
    values,
    error,
    submitting
  } = props

  const onSubmit = values => {
    return EnterpriseApi.createAffiliate({ ...values }).then(() => {
      window.location.reload()
    })
  }

  return (
    <>
      {children({ toggle: modal.toggle })}

      <modal.Modal>
        <modal.Header>Register Affiliate</modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <modal.Body>
            <div className='form-group'>
              <label>Name</label>
              <input
                type='text'
                name='name'
                value={values.name || ''}
                className='form-control'
                onChange={handleChange}
                placeholder='eg. Acme'
                required
              />
            </div>

            <div className='form-group'>
              <label>Email</label>
              <input
                type='email'
                name='email'
                value={values.email || ''}
                className='form-control'
                onChange={handleChange}
                placeholder='eg. invoicing@acme.com'
                required
              />
              <span className='form-text'>
                Billing Reports will be sent to this address
              </span>
            </div>

            <div className='form-group'>
              <label>Affiliate Type</label>
              <select
                name='type'
                value={values.type || ''}
                className='form-control'
                onChange={handleChange}
                required
              >
                <option value=''>- Select -</option>
                <option value='enterprise'>Enterprise</option>
                <option value='health'>Health</option>
                <option value='individual'>Individual</option>
              </select>
            </div>

            {error && (
              <p className='text-danger'>{error.response.data.error}</p>
            )}
          </modal.Body>

          <modal.Footer>
            <button
              className='btn btn-primary'
              type='submit'
              disabled={submitting}
            >
              {submitting ? 'Registering...' : 'Register'}
            </button>

            <modal.Cancel />
          </modal.Footer>
        </form>
      </modal.Modal>
    </>
  )
}

const form = FormHandler(RegisterParentAccount)
const modaled = withModalManager(form)

export default withRestricted(modaled, 'device::new_clients::accounts::create')
